/* This is for non-semantic-ui items */
/* See src/semantic/src/site/globals/site.overrides for semantic-ui items */

/*--------------------
    Homepage (doesn't import Semantic components so use CSS)
---------------------*/
.aw-homepage-button {
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  vertical-align: baseline;
  background: #e9e9e9;
  color: #1b1c1d;
  font-family: VT323, Arial, sans-serif;
  margin: 0 0.25em 0 0;
  padding: 0.3em 0.8em 0.3em;
  text-transform: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0;
  -webkit-box-shadow: 0 0 0 1px #ddd inset, 0 0 0 0 transparent;
  box-shadow: 0 0 0 1px #ddd inset, 0 0 0 0 transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: opacity 0.3s ease, background-color 0.3s ease,
    color 0.3s ease, background 0.3s ease, -webkit-box-shadow 0.3s ease;
  transition: opacity 0.3s ease, background-color 0.3s ease, color 0.3s ease,
    background 0.3s ease, -webkit-box-shadow 0.3s ease;
  -o-transition: opacity 0.3s ease, background-color 0.3s ease, color 0.3s ease,
    box-shadow 0.3s ease, background 0.3s ease;
  transition: opacity 0.3s ease, background-color 0.3s ease, color 0.3s ease,
    box-shadow 0.3s ease, background 0.3s ease;
  transition: opacity 0.3s ease, background-color 0.3s ease, color 0.3s ease,
    box-shadow 0.3s ease, background 0.3s ease, -webkit-box-shadow 0.3s ease;
  will-change: '';
  -webkit-tap-highlight-color: transparent;
  border-top: 2px solid #ffffff;
  border-right: 2px solid #b6b6b6;
  border-bottom: 2px solid #b6b6b6;
  border-left: 2px solid #ffffff;
  -webkit-box-shadow: -1px -1px 0 1px #fff, 1px 1px 0 1px #000 !important;
  box-shadow: -1px -1px 0 1px #fff, 1px 1px 0 1px #000 !important;
}
.aw-homepage-button:hover {
  background-color: #ffffff;
  border-top: 2px solid #ffffff;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 2px solid #ffffff;
  -webkit-box-shadow: -1px -1px 0 1px #fff, 1px 1px 0 1px #000 !important;
  box-shadow: -1px -1px 0 1px #fff, 1px 1px 0 1px #000 !important;
}
.aw-homepage-button:active {
  background-color: #ffffff;
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
  -webkit-box-shadow: -1px -1px 0 1px grey, 1px 1px 0 1px #ddd !important;
  box-shadow: -1px -1px 0 1px grey, 1px 1px 0 1px #ddd !important;
}

/* Awaken push container down below nav menu */
.ui.main.container,
.main.segment {
  margin-top: 3em;
}

/* Awaken try fixing model scroll issue on iOS safari */
.aw-webkit-overflow {
  -webkit-overflow-scrolling: touch;
}

.aw-right-buttons {
  float: right;
}
.aw-clearing:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.aw-register-intro {
  margin: -10px 0 20px 0;
}
.aw-terms {
  font-size: 0.8em;
  margin-top: 10px;
  text-align: center;
}
.aw-terms a {
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
}

/*--------------------
    Toastr
---------------------*/
.aw-toastr .toastr {
  border-radius: 0px !important;
  box-shadow: none !important;
  font-weight: 400 !important;
}
.aw-toastr .toastr.rrt-success {
  border-radius: 0.5em !important;
  background-color: rgb(250, 255, 189) !important;
  border: 2px solid black !important;
}
.aw-toastr .toastr.rrt-error {
  border-radius: 0.5em !important;
  border: 2px solid black !important;
}
.aw-toastr .rrt-title {
  font-size: 30px !important;
  font-family: VT323, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
  font-weight: 400 !important;
  color: black;
}
.aw-toastr .rrt-text {
  font-size: 20px !important;
  font-family: VT323, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
  font-weight: 400 !important;
  color: black;
}
.redux-toastr .toastr .close-toastr {
  color: black;
}
.redux-toastr .toastr .rrt-left-container .toastr-icon {
  fill: black;
  vertical-align: middle;
  margin-top: 5px;
}

.aw-justify-content-flex-end {
  display: flex;
  justify-content: flex-end;
}
.aw-justify-content-center {
  display: flex;
  justify-content: center;
}
.aw-justify-content-space-between {
  display: flex;
  justify-content: space-between;
}
.form-code-prefix {
  display: flex;
  line-height: 1.21428571em;
  border: 2px solid transparent;
  padding: 0.50396825em 0;
  font-size: 1em;
  width: 50px;
}
/*--------------------
    Aw Mobile menu (also see hamburger.css)
---------------------*/

.aw-hidden {
  display: none !important;
}
.aw-show {
  display: block !important;
}

@media only screen and (max-width: 767px) {
  .ui.stackable.menu .header.item {
    width: fit-content !important;
  }
  .ui.stackable.menu .header.item:hover {
    /* background-color: transparent;
    border: none;
    color: #1b1c1d; */
  }
}

/*--------------------
    Aw Post List Item
---------------------*/
.aw-post-body {
  padding: 300px;
}

.ui.vertical.menu .item {
  background: #e9e9e9;
}
.ui.vertical.menu a.item:hover {
  cursor: pointer;
  background: #01007f;
  color: #fff;
  border-top: 0px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
}

.text-right {
  text-align: right;
}
.mb-0 {
  margin-bottom: 0;
}

/* First submenu on mobile */
@media only screen and (max-width: 767px) {
  .ui.menu .item.aw-first {
    border-top: 2px solid #ccc;
  }
  .ui.vertical.menu .item.aw-first:hover {
    border-top: 0px solid #ccc;
    border-bottom: 0px solid #ccc;
  }
  .ui.vertical.menu a.item:hover {
    border-bottom: 0px solid transparent;
  }
  /* On mobile don't make the post more tall */
  .ui.items:not(.unstackable) > .item {
    flex-direction: column;
    margin: 0 0;
  }
  /* On mobile don't have margin on top of page */
  .ui.main.container,
  .main.segment {
    margin-top: 0;
  }
  /* On mobile remove extra lines above menu items */
  .ui.menu a.item,
  .ui.menu a.item:hover {
    border-bottom: none;
  }
  /* On mobile remove light line below 14p9.com at top left */
  .ui.menu a.item.aw-header-logo,
  .ui.menu a.item.aw-header-logo:hover {
    border-bottom: 0 !important;
    border-top: 0 !important;
  }
  /* On mobile remove light line below 14p9.com at top left */
  .ui.stackable.menu .item:before {
    display: none !important;
  }
  /* On mobile make height right */
  .ui.menu {
    min-height: 2.88888889rem;
  }
}
/* Hide this for all sizes except when logo is hidden (below) */
.aw-empty-logo-placeholder {
  display: none !important;
  /* padding: 0.94444444em 1.16666667em; */
}
@media only screen and (max-width: 500px) {
  /* On small mobile (iPhone) REMOVE LOGO ALTOGETHER! */
  .ui.menu a.item.aw-header-logo {
    display: none !important;
  }
  /* But show this placeholder */
  .aw-empty-logo-placeholder {
    display: block !important;
    min-height: 2.88888889rem;
  }
}
.aw-link {
  color: #1616ff;
  text-decoration: none;
  cursor: pointer;
}
.aw-link:hover {
  color: #0000af;
  text-decoration: none;
  cursor: pointer;
}
.aw-feed-admin-text p,
.ui.items > .item > .content .aw-feed-admin-text p {
  margin-bottom: 0;
}

.aw-user-agreement ol {
  counter-reset: item;
}
.aw-user-agreement li {
  display: block;
  padding-bottom: 10px;
}
.aw-user-agreement li:before {
  content: counters(item, '.') ' ';
  counter-increment: item;
}
